<template>
    <div class="facilityBox">
        <div class="form">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                <el-form-item label="付款ID" prop="userId" required>
                    <el-input type="number" v-model="form.userId" placeholder="请输入付款ID"></el-input>
                </el-form-item>
                <el-form-item label="收款ID" prop="forUserId" required>
                    <el-input type="number" v-model="form.forUserId" placeholder="请输入收款ID"></el-input>
                </el-form-item>
                <el-form-item label="金额" prop="amount" required>
                    <el-input type="number" v-model="form.amount" placeholder="请输入转账金额"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="form.remark" placeholder="请输入备注" :maxLength="500"></el-input>
                </el-form-item>
                <el-form-item style="text-align: center;">
                    <el-button type="primary" @click="submitForm('form')" :loading="submitLoading">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { LedgerToUser } from '@/api/common/common'
import verify from "@/utils/verify";
export default {
    data() {
        var checkPrice = (rule, value, callback) => {
            if (!verify._integer(value)) {
                callback(new Error('请输入正确的格式'));
            } else {
                callback();
            }
        };
        return {
            form: {
                userId: '',
                forUserId: '',
                amount: '',
                remark: ''
            },
            submitLoading: false,
            rules: {
                userId: [
                    { required: true, message: '请输入付款ID', trigger: 'blur' }
                ],
                forUserId: [
                    { required: true, message: '请输入收款ID', trigger: 'blur' }
                ],
                amount: [
                    { required: true, message: '请输入金额', trigger: 'blur' },
                    { validator: checkPrice, trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitLoading = true
                    LedgerToUser(this.form).then(res => {
                        this.$message.success("提交成功")
                        this.$refs[formName].resetFields()
                    }).finally(() => {
                        this.submitLoading = false
                    })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.form {
    width: 500px;
    margin: 60px auto;
}
</style>